import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useRef, useState, useContext } from 'react'
import { Section, Heading, Button } from 'src/components/Base'
import LanguageContext from 'src/context/LanguageContext'

import {
  cSection,
  cHeading,
  cInput,
  cInputWrapper,
  cButton,
  cError,
} from './styles.module.scss'

const LoginSection = ({ password, setIsLoggedIn }) => {
  const data = useStaticQuery(graphql`
    query protectedNoticeQuery {
      wp {
        themeGeneralSettings {
          privacyNotice {
            protectedHeading
            protectedPlaceholder
            protectedErrorText
            protectedHeadingEn
            protectedPlaceholderEn
            protectedErrorTextEn
          }
        }
      }
    }
  `)

  const inputEl = useRef(null)
  const [hasError, setHasError] = useState(false)
  const languageContext = useContext(LanguageContext)

  const checkLogin = () => {
    if (inputEl.current.value === password) {
      setIsLoggedIn(true)
      setHasError(false)
    } else {
      setIsLoggedIn(false)
      setHasError(true)
    }
  }

  return (
    <Section className={cSection}>
      <Heading className={cHeading} size={2}>
        {languageContext.currentLanguage === 'en'
          ? data.wp.themeGeneralSettings.privacyNotice.protectedHeadingEn
          : data.wp.themeGeneralSettings.privacyNotice.protectedHeading}
      </Heading>
      <div className={cInputWrapper}>
        <input
          type="password"
          placeholder={
            languageContext.currentLanguage === 'en'
              ? data.wp.themeGeneralSettings.privacyNotice
                  .protectedPlaceholderEn
              : data.wp.themeGeneralSettings.privacyNotice
                  .protectedPlaceholderEn
          }
          className={cInput}
          ref={inputEl}
        />
        <Button
          color="secondary"
          outline
          className={cButton}
          onClick={checkLogin}
        >
          {`>>`}
        </Button>
      </div>
      {hasError && (
        <Heading className={cError} size={4}>
          {languageContext.currentLanguage === 'en'
            ? data.wp.themeGeneralSettings.privacyNotice.protectedErrorText
            : data.wp.themeGeneralSettings.privacyNotice.protectedErrorText}
        </Heading>
      )}
    </Section>
  )
}

LoginSection.propTypes = {
  password: PropTypes.string.isRequired,
}

export default LoginSection
